// istanbul ignore file
import { translate, translateFormatter } from '@manomano-internal/i18n/esm/placeholders';
export const arrows = translate({
  "titleLeft": "Previous",
  "titleRight": "Next"
});
export const price = translate({
  "vatExclLabel": "EX.VAT",
  "vatInclLabel": "INC.VAT"
});
export const rating = {
  ariaLabel: (value: number) => translateFormatter<{
    value: number;
  }>("{value} out of 5", undefined, undefined, "en").format({
    value
  }) as string,
  reviewsLabel: (value: number) => translateFormatter<{
    value: number;
  }>("from {value, plural, =1 {# review} other {# reviews}}", undefined, undefined, "en").format({
    value
  }) as string
};
export const pagination = translate({
  "previousPageAriaLabel": "Previous page",
  "nextPageAriaLabel": "Next page"
});
export const counter = translate({
  "decrementAriaLabel": "Decrease",
  "incrementAriaLabel": "Increase"
});
export const inputPhone = {
  countriesLabels: translate({
    "BE": "Belgium",
    "DE": "Germany",
    "ES": "Spain",
    "FR": "France",
    "GB": "United Kingdom",
    "IT": "Italy",
    "LU": "Luxembourg",
    "CH": "Switzerland"
  })
};